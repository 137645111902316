(function($, site) {
  $(document).off('elc.iframe.addToBag').on('elc.iframe.addToBag', function(event, $iframe, data) {
    if (site.addToCart) {
      site.addToCart(data);
    } else if (prodcat && prodcat.ui && prodcat.ui.addToCart) {
      prodcat.ui.addToCart(data);
    }
  });

  $(document).off('elc.iframe.redirectRequested').on('elc.iframe.redirectRequested', function(event, $iframe, data) {
    if (!data['redirect_url']) {
      return false;
    }
    if (data['new_tab']) {
      window.open(data['redirect_url']);
    } else {
      window.location.href = data['redirect_url'];
    }
  });
})(jQuery, site || {});
